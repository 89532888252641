import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { computed, nextTick, onBeforeMount, reactive, toRefs, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
// import Logo from '@/components/LoginLogo/index.vue';
import { useMessage } from '@/hooks/web/useMessage';
import { handleEncrypt } from '@/utils/cipher';
import { setLocal, getLocal } from '@/utils/cache';
import httpApi from '../../api/httpApi';
const loginNameCacheName = 'LoginName';
export default {
  name: 'Login',
  directives: {
    focus: {
      inserted(el) {
        el.querySelector('input').focus();
      }
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const messageBox = useMessage();
    const recordNumber = computed(() => store.state.webInfoData.recordNumber);
    const recordInfo = computed(() => store.state.webInfoData.recordInfo);
    const backgroundUrl = computed(() => store.state.webInfoData.backgroundUrl);
    const platformName = computed(() => store.state.webInfoData.platformName);
    const refData = reactive({
      formRef: null,
      form: {
        username: '',
        password: '',
        tenantName: 'JMGPS'
      },
      rules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    });
    // 获取租户 ID
    const getTenantId = async () => {
      if (loginData.tenantEnable === 'true') {
        const res = await LoginApi.getTenantIdByName(loginData.loginForm.tenantName);
        authUtil.setTenantId(res);
      }
    };
    //获取网站信息
    const getLoginInfo = async () => {
      const {
        msg,
        code,
        data
      } = await httpApi.getLoginPage({
        url: window.location.hostname
      });
      data && store.commit('setWebInfoData', data);
    };
    nextTick(() => {
      getLoginInfo();
    });
    const login = async form => {
      return await store.dispatch('login', form);
    };
    function isPassword() {
      return true;
    }
    function validateUsername(rule, value, callback) {
      if (value === '') callback(new Error('用户名不能为空'));else callback();
    }
    function validatePassword(rule, value, callback) {
      if (!isPassword(value)) {
        callback(new Error('密码不能少于6位'));
      } else callback();
    }
    const handleRoute = () => {
      return refData.redirect === '/404' || refData.redirect === '/403' ? '/newHome' : refData.redirect;
    };
    const handleRouteNcbz = () => {
      return '/newHomeNcbz';
    };
    const handleLogin = async () => {
      if (!(await loginValidate())) return;
      try {
        refData.loading = true;
        setLocal(loginNameCacheName, refData.form.username);
        const params = {
          username: refData.form.username,
          password: refData.form.password
        };
        //handleEncrypt(refData.form.password)
        console.time('login api');
        const result = await login(params);
        console.timeEnd('login api');
        console.time('login');
        if (result.code === 0) {
          // 判断权限
          if (result.data.resourceIdList.length === 0) {
            return messageBox.warning(`${result.data.username} 您没有授权登录`);
          }
          //console.log("handleRoute" + handleRoute());
          if (refData.form.username === "ncbzadmin") {
            await router.push(handleRouteNcbz());
          } else {
            await router.push(handleRoute());
          }
          messageBox.success(`欢迎 ${result.data.username} 登录!`);
          return;
        }
        messageBox.warning(result.msg);
      } catch (error) {
        messageBox.error(error.msg);
      } finally {
        refData.loading = false;
      }
    };
    async function loginValidate() {
      try {
        const valid = await refData.formRef.validate();
        return valid;
      } catch (err) {
        console.error(err);
      }
      return false;
    }
    function handleOpen() {
      window.open('https://beian.miit.gov.cn/');
    }
    onBeforeMount(() => {
      store.commit('cleanLoginInfo');
      refData.form.username = getLocal(loginNameCacheName) || '';
      refData.form.password = '';
    });
    watchEffect(() => {
      refData.redirect = route.query && route.query.redirect || '/newHome';
    });
    onBeforeRouteLeave((to, from, next) => {
      next();
    });
    return {
      ...toRefs(refData),
      handleLogin,
      handleOpen,
      platformName,
      recordNumber,
      recordInfo,
      backgroundUrl
    };
  }
};